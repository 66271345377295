import axios from 'axios'
import { Token } from 'prismjs'
import React from 'react'
import {AuthModel, UserModel} from './_models'
import {setAuthAdmin, setCode, getCode} from '../core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL
//const _API_USER_ADMIN = process.env.REACT_APP_USER_ADMIN
//const _API_PSW_ADMIN = process.env.REACT_APP_PSW_ADMIN
export const ADMIN_USER = process.env.REACT_API_USER_ADMIN
export const ADMIN_PSW = process.env.REACT_API_PSW_ADMIN

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/Authenticate/verify_token`
export const LOGIN_URL = `${API_URL}/Authenticate/login`
export const REGISTER_URL = `${API_URL}/Authenticate/register`
export const VERIFICA_CODICE_URL = `${API_URL}/Authenticate/verifica-codice`
export const REQUEST_PASSWORD_URL = `${API_URL}/Authenticate/recupero-password`
export const CHANGE_PASSWORD_RECOVERY_URL = `${API_URL}/Authenticate/cambio-password-recupero`
//export const API_USER_ADMIN = `${_API_USER_ADMIN}`
//export const API_PSW_ADMIN = `${_API_PSW_ADMIN}`

// Server should return AuthModel
export function login(email?: string, password?: string) {
  console.log('Login: ' + email + ' - *****')
  const token = axios.post<AuthModel>(LOGIN_URL, {
    username: email,
    password: password,
  })
  console.log('Token: ' + token)
  return token
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(username: string, email: string, mobile: string) {
  //const auth = getAuthAdmin()
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    username: username,
    email: email,
    mobile: mobile
  },
  {
    headers:{
      'Content-Type': 'application/json',
    }
  })
}

export function verificaCodice(codice: string) {
  //const auth = getAuthAdmin()
  setCode(codice);
  console.log('Codice: ' + codice)
  return axios.post<{result: boolean}>(VERIFICA_CODICE_URL, {
    password: '',
    username: '',
    codice: codice
  },
  {
    headers:{
      'Content-Type': 'application/json',
    }
  })
}

export function cambioPasswordXCodice(password: string) {
  //const auth = getAuthAdmin()
  const codice = getCode();
  console.log('Cambio Password x Codice: ' + codice)
  return axios.post<{result: boolean}>(CHANGE_PASSWORD_RECOVERY_URL, {
    password: password,
    username: '',
    codice: codice
  },
  {
    headers:{
      'Content-Type': 'application/json',
    }
  })
}
/*
export async function loginAdmin(username?: string, password?: string) {
  try {
    //console.log('Utente Admin ' + username + ' Psw ' + password + '', _API_PSW_ADMIN, ADMIN_PSW)
    const {data: auth} = await login(username, password)
    console.log('loginAdmin: OK')
    setAuthAdmin(auth)
  } catch (error) {
    console.error(error)
  }
}
*/
export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    token: token,
  })
}
