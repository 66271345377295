import {AuthModel} from './_models'
import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import { ReactQueryDevtoolsPanel } from 'react-query/types/devtools'


const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_LOCAL_STORAGE_KEY_ADMIN = 'kt-auth-admin-react-v'
const API_USER_ADMIN = process.env.REACT_API_USER_ADMIN
const API_PSW_ADMIN = process.env.REACT_API_PSW_ADMIN
const AUTH_CODE_VERIFY = 'kt-auth-code-verify'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const getAuthAdmin = (): AuthModel | undefined => {
  if (!localStorage) {
    try
    {
      console.log('--Passo 0')
      const lgadmin = async () => {
      const {data: authAdmin} = await login('${API_USER_ADMIN}', '${API_PSW_ADMIN}')
      setAuthAdmin(authAdmin)
      return authAdmin
     }
    
    }
    catch(error)
    {
      console.log('Errore 1:' + error)
      return
    }
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY_ADMIN)
  if (!lsValue) {
    try
    {
      const lgadmin = async () => {
      const {data: authAdmin} = await login('${API_USER_ADMIN}', '${API_PSW_ADMIN}')
      setAuthAdmin(authAdmin)
      return authAdmin
     }
    
    }
    catch(error)
    {
      return
    }
  }
  else
  {

    try {
      const auth: AuthModel = JSON.parse(lsValue) as AuthModel
      if (auth) {
        // You can easily check auth_token expiration also
        return auth
      }
      else
      {
        try
        {
          const lgadmin = async () => {
          const {data: authAdmin} = await login('${API_USER_ADMIN}', '${API_PSW_ADMIN}')
          setAuthAdmin(authAdmin)
          return authAdmin
        }
        
        }
        catch(error)
        {
          return
        }
      }
    } catch (error) {
      console.error('AUTH LOCAL STORAGE ADMIN PARSE ERROR', error)
    }
  }
}

const getCode = (): string | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_CODE_VERIFY)
  if (!lsValue) {
    return
  }

  try {
    if (lsValue) {
      return lsValue
    }
  } catch (error) {
    console.error('AUTH_CODE_VERIFY PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setAuthAdmin = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY_ADMIN, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE ADMIN SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const removeAuthAdmin = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY_ADMIN)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE ADMIN REMOVE ERROR', error)
  }
}

const setCode = (code: string) => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.setItem(AUTH_CODE_VERIFY, code)
  } catch (error) {
    console.error('AUTH_CODE_VERIFY SAVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  console.log('setupAxios');
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, getAuthAdmin, setAuthAdmin, removeAuthAdmin, AUTH_LOCAL_STORAGE_KEY_ADMIN, setCode, getCode}
