import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {verificaCodice} from '../core/_requests'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useNavigate } from "react-router-dom";

const initialValues = {
    code_1: '',
    code_2:'',
    code_3:'',
    code_4:'',
    code_5:'',
    code_6:''
}



export function TwoSteps() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        verificaCodice(values.code_1 + values.code_2 + values.code_3 + values.code_4 + values.code_5 + values.code_6)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
            setSubmitting(true)            
            navigate("/auth/cambio-password");
          })
          .catch(() => {
            console.log('Eccezione Verifica Codice')
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Il codice non è corretto')
          })
      }, 1000)
    },
  })

  
  const handleKeyUp = (event: { key: string, target:any }) => {
    //console.log('User pressed: ', event);
 
    if (event.key === 'Canc' || event.key === 'Backspace' || event.key === '0' || event.key === '1' || event.key === '2' || event.key === '3' || event.key === '4' || event.key === '5' || event.key === '6' || event.key === '7' || event.key === '8' || event.key === '9')
    {
        if (event.key === 'Backspace') {
        // 👇️ your logic here
        if(event.target.previousSibling)
            event.target.previousSibling.focus();
        }
        else if(event.key !== 'Canc')
        {
            if(event.target.nextSibling)
            {
                event.target.nextSibling.focus();
            }
            else
            {
                console.log('POSSIAMO PROVARE')
                event.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }
                
        }
    }
  };

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
            <img alt="Logo" className="mh-125px" src={toAbsoluteUrl('/media/svg/misc/smartphone-2.svg')}/>
        </div>
        <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Verifica in due step</h1>
            <div className="text-muted fw-semibold fs-5 mb-5">Inserisci il codice che ti abbiamo inviato</div>
            <div className="fw-bold text-dark fs-3">******2408</div>
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
             Il codice inserito non è corretto.
            </div>
          </div>
        )}
       
        {/* end::Title */}

        {/* begin::Form group */}

        <div className="mb-10">
            <div className="fw-bold text-start text-dark fs-6 mb-1 ms-1">Type your 6 digit security code</div>
            <div className="d-flex flex-wrap flex-stack">
                <input  autoFocus = {true} {...formik.getFieldProps('code_1')} type="text" name="code_1" maxLength={1} onFocus={(e) => e.target.value = ''} onKeyUp={handleKeyUp} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"  />
                <input {...formik.getFieldProps('code_2')} type="text" name="code_2" maxLength={1} onFocus={(e) => e.target.value = ''} onKeyUp={handleKeyUp} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"  />
                <input {...formik.getFieldProps('code_3')} type="text" name="code_3" maxLength={1} onFocus={(e) => e.target.value = ''} onKeyUp={handleKeyUp} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"  />
                <input {...formik.getFieldProps('code_4')} type="text" name="code_4" maxLength={1} onFocus={(e) => e.target.value = ''} onKeyUp={handleKeyUp} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"  />
                <input {...formik.getFieldProps('code_5')} type="text" name="code_5" maxLength={1} onFocus={(e) => e.target.value = ''} onKeyUp={handleKeyUp} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"  />
                <input {...formik.getFieldProps('code_6')} type="text" name="code_6" maxLength={1} onFocus={(e) => e.target.value = ''} onKeyUp={handleKeyUp} className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"  />
            </div>
        </div>
       
        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Invia</span>
            {loading && (
              <span className='indicator-progress'>
                Attendere...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Annulla
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}


